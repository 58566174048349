@import '~@react-md/utils/dist/mixins';

.container {
  max-width: 90%;
  margin: 40px auto;
}

.header {
  opacity: 0.98;
  outline: 1px dotted royalblue;
  background-color: white;
  padding: 16px;
  margin-bottom: 16px;
}

.title {
  margin-bottom: 20px;
}

.settings {
  display: flex;
  flex-flow: column wrap;
  margin-bottom: 20px;

  @include rmd-utils-tablet-media {
    flex-flow: row nowrap;

    & > div {
      margin-right: 10px;
    }
  }
}

.articleContainer {
  @include rmd-utils-tablet-media {
    display: grid;
    grid-template-columns: 1fr;
  }
}
