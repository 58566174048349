@import '../../variables';
@import '~@react-md/utils/dist/mixins';

.panel {
  margin-bottom: 10px;
  opacity: 0.98;
  transition: all;
  transition-duration: 0.5s;
  
  &:hover {
    opacity: 1;
  }
}

.list {
  padding-left: 16px;
  margin: 0 0 16px;
  color: $rmd-deep-orange-900;

  @include rmd-utils-tablet-media {
    margin-left: 186px;
  }

  > li {
    margin-bottom: 8px;
  }
}
