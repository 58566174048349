@import '../../../variables';
@import '~@react-md/utils/dist/mixins';

.header {
  width: 100%;

  @include rmd-utils-tablet-media {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
  }
}

.imgLink {
  .img {
    display: block;
    transition: all;
    transition-duration: 0.5s;
    border: 1px solid $rmd-deep-orange-900;
    opacity: 0.9;
  }

  &:hover {
    .img {
      border-color: $rmd-deep-orange-700;
      opacity: 1;
    }
  }
}

.title {
  text-align: left;

  @include rmd-utils-tablet-media {
    margin-left: 20px;
  }

  .bold {
    font-weight: bold;
  }

  :global(.rmd-typography) {
    margin: 0 0 4px;
  }
}
