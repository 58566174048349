@import './variables';
@import '~react-md/dist/react-md';

@include react-md-utils;

html {
  --s: 36px; /* control the size*/
  --c1: #878282;
  --c2: #d2d3d5;
  --c3: #727c7e;
  
  --g1: conic-gradient(at calc(250%/3) calc(50%/3),var(--c2) 60deg,#0000 0 300deg,var(--c1) 0);
  --g2: conic-gradient(at calc(50%/3) 50%,#0000 75%,var(--c1) 0);
  --g3: conic-gradient(at calc(100%/3) 50%,#0000 75%,var(--c2) 0);
  --g4: conic-gradient(from 59deg at calc(200%/3) calc(249%/9),var(--c3) 61deg,#0000 62deg);
  --g5: conic-gradient(from 60deg at 50% calc(250%/3),#f1f1f1 /*4th color*/ 60deg,var(--c1) 0 120deg,#0000 0);
  --_p: calc(9*tan(30deg)*var(--s));
  background:
    var(--g1) calc(-1*var(--s)) 0,var(--g1) calc(2*var(--s)) var(--_p),
    var(--g2),var(--g2) calc(3*var(--s)) var(--_p),
    var(--g3),var(--g3) calc(3*var(--s)) var(--_p),
    var(--g4),var(--g4) calc(3*var(--s)) var(--_p),
    var(--g5) calc(3*var(--s)) 0,var(--g5) 0 var(--_p) var(--c3);
  background-size: calc(6*var(--s)) calc(2*var(--_p))
}

body {
  font-family: Helvetica, Arial, sans-serif;
}

label {
  &:hover {
    cursor: pointer;
  }
}

.rmd-link {
  &:link {
    color: $rmd-deep-orange-900;

    svg {
      fill: $rmd-deep-orange-900;
    }
  }

  &:visited,
  &:hover,
  &:active {
    color: $rmd-deep-orange-700;

    svg {
      fill: $rmd-deep-orange-700;
    }
  }
}
